import React from "react";
import IndexPage from "./index"

const IndexPageLT = () => (
  <IndexPage i18n="lt"/>
);

export default IndexPageLT;


